<div *ngIf="reviewUrl">
  <div *ngIf="!isReviewSubmitted; else thanksForReview"
       class="border border-4 border-warning rounded p-3">
    <div class="d-flex justify-content-between align-items-center">

      <span i18n="@@app.ptReview.title"
            class="fw-bold">Please leave us a review!</span>

      <div class="digital-care-guide-logo"></div>
    </div>

    <div i18n="@@app.ptReview.description"
         class="py-3">
      Sharing your experience of digi.me will help others make an informed choice about which PGO they should use. It
      should take no more than 5 minutes.
    </div>

    <app-button type="secondary"
                (clicked)="openReviewUrl()">
      <div class="d-flex align-items-center gap-2">
        <svg class="star-svg">
          <use attr.xlink:href="{{ svg.PATHS.RoundedStar }}"></use>
        </svg>

        <span i18n="@@app.ptReview.btn">Leave a review</span>
      </div>
    </app-button>

  </div>
</div>

<ng-template #thanksForReview>
  <div class="border border-4 border-warning rounded p-3">
    <div class="d-flex justify-content-between align-items-center">
      <span class="fw-bold">
        <span i18n="@@app.ptReviewThanks.title">Thank you</span> {{ userAd?.displayName }}
      </span>

      <div class="digital-care-guide-logo"></div>
    </div>

    <div i18n="@@app.ptReviewThanks.description"
         class="py-3">
      It might be a few days before you see your review published. Patiëntenfederatie Nederland check each review
      before
      publishing it.
    </div>
  </div>
</ng-template>