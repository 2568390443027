<div class="card-home-page">
  <div class="card-home-page-container">
    <app-button type="secondary"
                label="View your Patient Summary"
                (clicked)="navigateTo('ips')"
                i18n-label="@@app.ips.button"></app-button>

    <div class="icon-rotate-15deg">
      <svg aria-hidden="true">
        <use attr.xlink:href="{{ svg.PATHS.IPS }}"></use>
      </svg>
    </div>

    <div i18n="@@app.ips.description"
         class="py-3">
      Your Patient Summary gives healthcare professionals a quick and reliable snapshots of your medical history,
      covering key details like emergency contacts, allergies, medications, vaccines, and more. All in one place, so
      you can receive the right care when you need it.
    </div>
  </div>
</div>