import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Svg } from '@globals';
import { ButtonComponent } from '@layout';
import { SharedModule } from '@shared';

@Component({
  standalone: true,
  selector: 'app-ips-banner',
  templateUrl: './ips-banner.component.html',
  imports: [SharedModule, ButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpsBannerComponent {
  readonly svg = Svg;

  constructor(private readonly router: Router) {}

  async navigateTo(path: string): Promise<void> {
    await this.router.navigate([`${$localize.locale}`, path]);
  }
}
