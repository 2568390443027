<div class="card-home-page">
  <div class="card-home-page-container">
    <div class="py-3 fw-bold"
         i18n="@@app.promo.mobile.title">Always have your records with you!</div>

    <div *ngIf="!device.isMobile()"
         class="icon-upsell">
      <svg aria-hidden="true">
        <use attr.xlink:href="{{svg.PATHS.Upsell}}"></use>
      </svg>
    </div>

    <div>
      <span *ngIf="device.isIOS() || !device.isMobile()"
            tabindex="0"
            #iosEn
            (mouseenter)="iOSApp()"
            (mouseleave)="hideQR()"
            (click)="routeAppStore()">
        <svg class="ios-svg"
             aria-hidden="true">
          <use attr.xlink:href="{{ isEnglish ? svg.PATHS.IOSEn : svg.PATHS.IOSNl }}"></use>
        </svg>
      </span>

      <span *ngIf="device.isAndroid() || !device.isMobile()"
            tabindex="1"
            #androidEn
            (mouseenter)="androidApp()"
            (mouseleave)="hideQR()"
            (click)="routePlayStore()">
        <svg class="android-svg"
             aria-hidden="true">
          <use attr.xlink:href="{{ isEnglish ? svg.PATHS.AndroidEn : svg.PATHS.AndroidNl }}"></use>
        </svg>
      </span>
    </div>

    <div i18n="@@app.promo.mobile.text"
         class="py-3">
      Get digi.me on your phone and access all your health records anywhere. Secure biometric login means your info is
      just a tap away, whether at the doctor's or on the go.
    </div>
  </div>

</div>

<div class="card-home-page qr-container"
     *ngIf="showiOSQR"
     [ngStyle]="{ position: 'absolute', top: qrPosition.top + 'px', left: qrPosition.left + 'px' }">
  <div class="card-home-page-container">
    <div>
      <svg class="qr-svg"
           aria-hidden="true">
        <use xlink:href="./assets/images/qr.svg#ios"></use>
      </svg>
    </div>
    <div class="text-center"
         i18n="@@app.promo.mobile.hover.ios">
      Tap to open the Apple App Store or scan from your phone.
    </div>
  </div>
</div>

<div class="card-home-page qr-container"
     *ngIf="showAndroidQR"
     [ngStyle]="{ position: 'absolute', top: qrPosition.top + 'px', left: qrPosition.left + 'px' }">
  <div class="card-home-page-container">
    <div>
      <svg class="qr-svg"
           aria-hidden="true">
        <use xlink:href="./assets/images/qr.svg#android"></use>
      </svg>
    </div>
    <div class="text-center"
         i18n="@@app.promo.mobile.hover.android">
      Tap to open in the Google Play Store or scan from your phone.
    </div>
  </div>
</div>