import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UserAD } from '@digi.me/models';
import { Svg } from '@globals';
import { ButtonComponent } from '@layout';
import { SharedModule } from '@shared';

@Component({
  standalone: true,
  selector: 'app-patient-federation-review-banner',
  templateUrl: './patient-federation-review-banner.component.html',
  styles: [
    `
      .star-svg {
        height: 24px;
        width: 24px;
      }
    `,
  ],
  imports: [SharedModule, ButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientFederationReviewBannerComponent {
  @Input({ required: true }) reviewUrl?: string | null;
  @Input({ required: true }) userAd?: UserAD | null;
  @Input() isReviewSubmitted?: boolean | null | undefined = false;

  @Output() readonly reviewSubmitted = new EventEmitter();

  readonly svg = Svg;

  openReviewUrl(): void {
    if (!this.reviewUrl) {
      return;
    }

    window.open(this.reviewUrl, '_blank');

    this.reviewSubmitted.emit(true);
  }
}
