import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { App, Device, Svg } from '@globals';

@Component({
  standalone: true,
  selector: 'app-upsell-banner',
  templateUrl: './upsell-banner.component.html',
  styles: [
    `
      .ios-svg {
        height: 40px;
        width: 130px;
      }

      .android-svg {
        height: 40px;
        width: 140px;
      }

      .qr-svg {
        height: 200px;
      }

      .qr-container {
        width: 250px;
        height: auto;
      }
    `,
  ],
  imports: [CommonModule],
})
export class UpsellBannerComponent {
  @ViewChild('iosEn') iOSEn!: ElementRef;
  @ViewChild('iosNl') iOSNl!: ElementRef;
  @ViewChild('androidEn') androidEn!: ElementRef;
  @ViewChild('androidNl') androidNl!: ElementRef;

  showiOSQR = false;
  showAndroidQR = false;
  qrPosition = { top: 0, left: 0 };

  svg = Svg;
  device = Device;

  get isEnglish() {
    return $localize.locale === 'en-US';
  }

  iOSApp() {
    this.showiOSQR = true;
    this.positionQR(this.iOSEn ?? this.iOSNl);
  }

  androidApp() {
    this.showAndroidQR = true;
    this.positionQR(this.androidEn ?? this.androidNl);
  }

  hideQR() {
    this.showiOSQR = false;
    this.showAndroidQR = false;
  }

  routeAppStore(): void {
    window.open(App.Urls.iOS, '_blank');
  }

  routePlayStore(): void {
    window.open(App.Urls.Android, '_blank');
  }

  positionQR(element: ElementRef) {
    if (element) {
      const rect = element.nativeElement.getBoundingClientRect();
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
      const offsetLeft = 20;
      const offsetTop = 350;

      this.qrPosition = {
        top: rect.top + scrollTop - offsetTop,
        left: rect.left + scrollLeft - offsetLeft,
      };
    }
  }
}
